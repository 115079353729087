"use client";

import Image from "next/image";
import { useState } from "react";

function AboutSection2() {
    const [activeIndex, setActiveIndex] = useState(0);
    const data = [
        {
            text: "Quickly cite, share, and reference with ease",
            largeImg: "/imgs/about_section_2_img.svg",
            smallImg: "/imgs/about_section_2_img1.svg",
        },
        {
            text: "Expand abstracts and access full-text PDFs instantly",
            largeImg: "/imgs/about_section2_LImg2.svg",
            smallImg: "/imgs/about_section_2_img2.svg",
        },
        {
            text: "Translate abstracts and use AI to simplify the text",
            largeImg: "/imgs/about_section2_LImg3.svg",
            smallImg: "/imgs/about_section_2_img3.svg",
        },
        {
            text: "Save references all at once",
            largeImg: "/imgs/about_section2_LImg4.svg",
            smallImg: "/imgs/about_section_2_img4.svg",
        },
    ];
    return (
        <div className="flex flex-col md:flex-row items-start justify-between w-full bg-white px-4 py-8 md:py-32 lg:py-40 md:px-8 lg:px-12 gap-6 md:gap-12 lg:gap-16">
            {/* Left Section */}
            <div className="flex flex-col items-start gap-4 md:gap-6 w-full md:w-1/2">
                <div>
                    <span className="borderStyle px-3 py-1 md:px-4 md:py-2 text-sm md:text-lg text-primaryBlack">
                        02
                    </span>
                </div>
                <h2 className="text-xl md:text-2xl lg:text-5xl font-medium text-primaryBlack break-words text-left max-w-full md:max-w-sm lg:max-w-md">
                    Effortless Exploration
                </h2>
                <p className="text-sm md:text-base lg:text-lg font-normal leading-6 md:leading-7 text-SecondaryGray max-w-full md:max-w-md lg:max-w-lg">
                    Make browsing literature as intuitive as scrolling through social media
                </p>
            </div>

            {/* Right Section */}
            <div className="flex flex-col items-start gap-6 justify-center w-full md:w-1/2">
            {/* Text and Images */}
            <div className="flex flex-col gap-4">
                {data.map((item, index) => (
                    <div key={index} className="flex items-start gap-2">
                        {/* Circle */}
                        <span
                            className={`w-[22px] h-[20px]  rounded-full ${
                                activeIndex === index ? "bg-thirdTurquoise" : "bg-gray-300"
                            }`}
                        ></span>

                        {/* Text */}
                        <div className="flex flex-col gap-2">
                            <p
                                onClick={() => setActiveIndex(activeIndex === index ? 0 : index)} // Toggle active index
                                className={`text-sm md:text-base lg:text-xl font-normal leading-6 md:leading-7 text-primaryBlack max-w-full md:max-w-md lg:max-w-lg cursor-pointer ${
                                    activeIndex === index ? "text-thirdTurquoise" : "text-primaryBlack"
                                }`}
                            >
                                {item.text}
                            </p>

                            {/* Images for Large Devices */}
                            <div className="hidden md:block"> {/* Hidden on small screens */}
                                {activeIndex === index && (
                                    <Image
                                        width={700}
                                        height={500}
                                        className="w-full h-auto max-w-[100%] sm:max-w-[100%] rounded object-cover"
                                        src={item.largeImg}
                                        alt={`About MedEngine Image ${index + 1}`}
                                    />
                                )}
                            </div>

                            {/* Images for Small Devices */}
                            <div className="block md:hidden"> {/* Visible only on small screens */}
                                <Image
                                    width={600}
                                    height={400}
                                    className="w-full h-auto max-w-[100%] sm:max-w-[80%] rounded object-cover"
                                    src={item.smallImg}
                                    alt={`About MedEngine Small Image ${index + 1}`}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
}

export default AboutSection2;
