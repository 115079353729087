"use client"
import { useState } from "react";

export default function FAQSection() {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index: any) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section className="bg-white pt-8 pb-0 flex justify-center md:py-24">
            <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 text-center">
                <div className="mb-10 lg:mb-16">
                    <span className="borderStyle w-fit mx-auto bg-gray-300 px-4 py-2 text-sm md:text-lg text-primaryBlack">
                        FAQ
                    </span>
                    <h2 className="text-2xl font-bold text-gray-900 leading-[3.25rem] mt-6 mb-5 md:text-4xl">
                        You might have questions
                    </h2>
                </div>
                <div className="accordion-group space-y-6">
                    {/* Existing FAQ Items */}
                    {[
                        {
                            question: "Who can use MedEngine?",
                            answer:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos perspiciatis doloremque, ab numquam sed tenetur alias nesciunt eius nostrum omnis suscipit mollitia esse, velit autem, atque temporibus reprehenderit quasi laboriosam!",
                        },
                        {
                            question: "Can I search in different languages?",
                            answer:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos perspiciatis doloremque, ab numquam sed tenetur alias nesciunt eius nostrum omnis suscipit mollitia esse, velit autem, atque temporibus reprehenderit quasi laboriosam!",
                        },
                        {
                            question: "What pricing plans are available?",
                            answer:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos perspiciatis doloremque, ab numquam sed tenetur alias nesciunt eius nostrum omnis suscipit mollitia esse, velit autem, atque temporibus reprehenderit quasi laboriosam!",
                        },
                        {
                            question: "How reliable are the articles on MedEngine?",
                            answer:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos perspiciatis doloremque, ab numquam sed tenetur alias nesciunt eius nostrum omnis suscipit mollitia esse, velit autem, atque temporibus reprehenderit quasi laboriosam!",
                        },
                        {
                            question: "How do I get started?",
                            answer:
                                "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos perspiciatis doloremque, ab numquam sed tenetur alias nesciunt eius nostrum omnis suscipit mollitia esse, velit autem, atque temporibus reprehenderit quasi laboriosam!",
                        },
                    ].map((faq, index) => (
                        <div
                            key={index}
                            className={`accordion border-b border-gray-300 pb-2 md:pb-6 transition-all duration-300`}
                        >
                            <button
                                className="accordion-toggle group inline-flex items-center justify-between text-xl font-medium text-gray-600 w-full transition duration-500 hover:text-primaryBlue"
                                onClick={() => toggleAccordion(index)}
                            >
                                <h5 className="text-sm text-start md:text-xl">{faq.question}</h5>
                                <svg
                                    className={`text-gray-900 transition-transform duration-500 group-hover:text-primaryBlue ${openIndex === index ? "rotate-45" : ""
                                        }`}
                                    width="22"
                                    height="22"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 5V19"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M5 12H19"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                            </button>
                            <div
                                className={`accordion-content mt-4 text-sm text-gray-600 overflow-hidden transition-all duration-300 ${openIndex === index ? "max-h-screen" : "max-h-0"
                                    }`}
                            >
                                <p className="py-2 text-start md:text-xl">{faq.answer}</p>
                            </div>
                        </div>
                    ))}

                    {/* New Card Section */}
                    <div className="w-full bg-gray-200 border border-gray-300 rounded-lg py-2 px-2 md:py-4 md:px-6 flex gap-2 justify-between items-center">
                        <h3 className="text-xs md:text-xl font-semibold text-gray-900">
                            Still have questions?
                        </h3>
                        <button className="text-thirdTurquoise border border-thirdTurquoise text-sm md:text-lg font-medium px-6 py-2 rounded-lg shadow-sm hover:bg-thirdTurquoise hover:text-white transition">
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </section>

    );
}
